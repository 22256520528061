import * as React from 'react';

import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/Layout';
import Seo from '../components/Seo';



const NotFoundPage = () => {

  const { t } = useTranslation();

  return (
    <Layout >
      <Seo
        title={"404"}
        description={t("404")}
      />
      <div className="max-w-md mx-auto overflow-hidden h-full justify-center items-center self-center flex rounded-xl">
        <div className="p-6 bg-grey-light rounded-xl dark:bg-grey-dark shadow-md">
          <div className="uppercase tracking-wide text-3xl font-semibold p-4 self-center text-center text-transparent bg-clip-text bg-gradient-to-tr from-blue to-yellow via-pink animate-gradient-xy">
            {t("404")}
          </div>
          <div
            className="flex flex-row justify-around"
          >
            <Link
              to="/"
              className="flex flex-row justify-center items-center py-2 px-4 m-4 mt-0 bg-blue text-white-light dark:text-white-dark text-center text-base font-semibold shadow-md rounded-lg transition duration-320 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            >
              <div className='px-2'>
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
              {t("Back")}
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;